//Fonts
$archivo: 'Times New Roman', Times, serif;
$playfair: 'Times New Roman', Times, serif;
$qwitcher: 'Times New Roman', Times, serif;

//Colors
$white: #ffffff;
$black: #000000;
$salmon: #232323;
$text: #fff;
$hover: #BB4C33;

// Pixels X REM
$browser-context: 16;
@function rem($pixels, $context: $browser-context) {
	@return #{calc($pixels / $context)}rem;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-15px);
	}
	60% {
		transform: translateY(-5px);
	}
}